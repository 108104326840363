import './Cube.css'

function Cube({size}) {

    //Can be dynamically resized and placed wherever

    return (
    <div className='cube' style={{"--size":size}}>
        <div className='top'></div>
        <div>
            <span style={{"--i":0}}></span>
            <span style={{"--i":1}}></span>
            <span style={{"--i":2}}></span>
            <span style={{"--i":3}}></span>
        </div>
    </div>
    );
}

export default Cube;
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import "./Layout.css"
  

function Layout() {


    const nav = useNavigate();
    const loc = useLocation();

    //ensures home page is default route
    useEffect(() => {
        console.log(loc)
        if (loc["pathname"] == "/") {
        nav("/home");
    }
     }, []);

    return (
        <div id="layout">
            <Navbar/>
            <div className="content-container">
            <Outlet></Outlet>
            </div>
            <div id="copyright"><h1>&copy; Nicola Savino 2023</h1></div>
        </div>
    )
}

export default Layout;
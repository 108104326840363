


function Contact() {

    return (
        <div id="contact">
            <h1>Contact</h1>
            <ul>
                <li><h3>Phone: <a href="tel: +4032006607">403-200-6607</a></h3></li>
                <li><h3><a href="mailto:nicola.savino1@ucalgary.ca">Email</a></h3></li>
                <li><h3><a href="https://www.linkedin.com/in/nicola-savino/">LinkedIn</a></h3></li>
            </ul>
        </div>
    )
}

export default Contact;
import "./Projects.css"

function Projects() {


    return (
        <div>
            <h1>Projects</h1>
            <div className="project">
                <h2>Wordle</h2>
                <p>I built a clone of wordle as part of a school project. It was a great introduction to Javascript and required alot of deep thinking on how to implement everything. 
                    Once I got the ball rolling though, it was just a matter of putting all the features together and making it look nice.
                </p>
                <h3>Click <a href="http://nicksavino.github.io/wordle/wordle.html">Here</a> to play</h3>
            </div>
            <div className="project">
                <h2>React NoteTaking App</h2>
                <p>
                    A lightweight note-taking app that persists data in the browsers localstorage. This project was built using React and was a great introduction to the framework. 
                    I found it quite difficult to wrap my head around the concept of components and hooks, 
                    but once I understood the general concepts it became much easier to build components and have them communicate with each other. 
                </p>
                <h3>Click <a href="https://lotionesnotesnicola.netlify.app/notes">Here</a> to try</h3>
            </div>
        </div>
    )
}

export default Projects;
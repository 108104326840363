

function NoPage() {
    return (
        <div>
            <h1>Page Does Not Exist</h1>
        </div>
    )
}

export default NoPage;
import './Home.css'

function Home() {

    return (
        <div id="home">
            <div id='content'>
                <div><h1>Welcome</h1></div>
                <img id="headshot" src="./images/Headshot.JPG" width={300} alt='No Img'/>
            </div>
            <div>
                <p>
                    I am a second year software engineering student who is minoring in Aerospace.
                    I am interest in embedded programming and the emerging field of IOT, and
                    other low-level programming applications. 
                </p>
                <br/>
                <p>
                    I am a Mission Operations member of the CalgaryToSpace team, and I am currently working on
                    mission planning, writing lightweight scripts and running orbital simulations for the upcoming
                    launch. I have had one internship of work experience at a small startup company, and am currently
                    seeking a summer internship for 2023. 
                </p>
                <br/>
                <p>
                    Outside of school, I enjoy playing the piano, video games, fishing, and working out. I like to dabble
                    around with my Arduino kits and spare electronics, and I am currently working on a project to automate
                    my morning coffee! 
                </p>
                <br/>
                <p>
                    If you are looking for a hard-working, dedicated, and passionate student to work for you this summer,
                    please feel free to contact me at my email listed on the sidebar. 
                </p>
            </div>
        </div>
    )
}

export default Home;